import React, { useState, useRef, useEffect } from 'react';
import { Button, Slider, FormControl, FormLabel, Paper, Box, LinearProgress } from '@mui/material';

function Questionnaire({ questions, onSubmit }) {
    const [answers, setAnswers] = useState({});
    const [unanswered, setUnanswered] = useState(new Set());
    const [currentPage, setCurrentPage] = useState(0);
    const progress = Math.round((Object.keys(answers).length / questions.length) * 100);
    const questionsPerPage = 5;

    const questionRefs = useRef({});

    useEffect(() => {
        questionRefs.current = questions.reduce((acc, question) => {
            acc[question.key] = React.createRef();
            return acc;
        }, {});
    }, [questions]);

    const handleChange = (questionId, value) => {
        setAnswers({ ...answers, [questionId]: value });
        if (unanswered.has(questionId)) {
            setUnanswered(new Set([...unanswered].filter(qId => qId !== questionId)));
        }
    };

    const handleSubmit = async () => {
        const newUnanswered = new Set();
        questions.forEach(question => {
            if (!answers[question.key]) {
                newUnanswered.add(question.key);
            }
        });

        setUnanswered(newUnanswered);

        if (newUnanswered.size > 0 && process.env.ENVIRONMENT === "production") {
            const firstUnansweredKey = [...newUnanswered][0];
            questionRefs.current[firstUnansweredKey].current.scrollIntoView({ behavior: 'smooth' });
        } else {
            // Convert 1-100 scale to 1-5 scale for submission
            const submissionAnswers = Object.keys(answers).reduce((acc, key) => {
                acc[key] = Math.round((answers[key] / 100) * 4) + 1;
                return acc;
            }, {});

            onSubmit(Object.keys(submissionAnswers).map(key => ({ q_id: key, r: submissionAnswers[key] })));
        }
    };

    const totalPages = Math.ceil(questions.length / questionsPerPage);
    const canProceedToNextPage = questions
        .slice(currentPage * questionsPerPage, (currentPage + 1) * questionsPerPage)
        .every(question => answers[question.key]);

    return (
        <div>
            {questions.slice(currentPage * questionsPerPage, (currentPage + 1) * questionsPerPage).map((question, index) => (
                <Paper
                    ref={questionRefs.current[question.key]}
                    sx={{
                        padding: 2,
                        marginBottom: 3,
                        backgroundColor: unanswered.has(question.key) ? '#ffdada' : 'inherit',
                    }}
                    key={index}
                >
                    <FormControl component="fieldset" sx={{ width: '100%' }}>
                        <FormLabel component="legend" sx={{ textAlign: 'center' }}>
                            {question.question}
                        </FormLabel>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Slider
                                value={answers[question.key] || 50}
                                min={1}
                                max={100}
                                step={1}
                                onChange={(event, newValue) => handleChange(question.key, newValue)}
                                valueLabelDisplay="auto"
                                sx={{ flexGrow: 1 }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 1 }}>
                            <span>그렇지않다</span>
                            <span>그렇다</span>
                        </Box>
                    </FormControl>
                </Paper>
            ))}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 2 }}>
                <Button
                    variant="contained"
                    disabled={currentPage === 0}
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))}
                >
                    Previous
                </Button>
                <Box sx={{ flexGrow: 1, mx: 2 }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                {currentPage < totalPages - 1 ? (
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!canProceedToNextPage}
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages - 1))}
                    >
                        Next
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                )}
            </Box>
        </div>
    );
}

export default Questionnaire;
