import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Questionnaire from './Questionnaire';
import { Container, Box, Divider, Button, TextField, Typography } from '@mui/material';
import Results from "./Results";
import ProgressIndicator from "./ProgressIndicator";
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

const ENV = process.env.ENVIRONMENT;
// const API_URL = ENV === 'production' ? "https://api.mentai.jypark.dev" : "http://localhost:5001";
const API_URL = "https://api.mentai.jypark.dev"

const usePageTracking = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);
};

function AppContent() {
    const [name, setName] = useState('');
    const [questions, setQuestions] = useState([]);
    const [results, setResults] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [tempName, setTempName] = useState('');

    usePageTracking();

    useEffect(() => {
        axios.get(`${API_URL}/questions`)
            .then(response => {
                setQuestions(response.data);
                ReactGA.event({
                    category: 'Questions',
                    action: 'Fetch',
                    label: 'Fetch Questions'
                });
            })
            .catch(error => {
                console.error('Error fetching data: ', error);
                ReactGA.event({
                    category: 'Error',
                    action: 'Fetch Questions Error',
                    label: error.message
                });
            });
    }, []);

    const handleSubmit = (answers) => {
        setIsLoading(true);
        ReactGA.event({
            category: 'Form',
            action: 'Submit',
            label: 'Questionnaire Submitted'
        });
        axios.post(`${API_URL}/submit`, { answers })
            .then(response => {
                setResults(response.data);
                setIsLoading(false);
                ReactGA.event({
                    category: 'Results',
                    action: 'Received',
                    label: 'Results Received'
                });
            })
            .catch(error => {
                console.error('Error submitting answers: ', error);
                setIsLoading(false);
                ReactGA.event({
                    category: 'Error',
                    action: 'Submit Answers Error',
                    label: error.message
                });
            });
    };

    const handleNameSubmit = () => {
        setName(tempName);
        ReactGA.event({
            category: 'User',
            action: 'Enter Name',
            label: 'Name Submitted'
        });
    };

    return (
        <Container maxWidth="lg" sx={{ my: 4 }}>
            <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                <Box component="img" src="/logo.png" alt="MentAI Logo" sx={{ width: { xs: '150px', md: '200px' }, height: 'auto' }} />
            </Box>
            <Divider />
            <Box my={4}>
                {name ? (
                    isLoading ? (
                        <ProgressIndicator />
                    ) : results ? (
                        <Results response={results} />
                    ) : (
                        <>
                            <Questionnaire questions={questions} onSubmit={handleSubmit} />
                            {ENV === 'dev' && (
                                <Box mt={2}>
                                    <Button variant="contained" color="secondary" onClick={handleSubmit}>
                                        빠른 제출
                                    </Button>
                                </Box>
                            )}
                        </>
                    )
                ) : (
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Typography variant="h6" sx={{ margin: 1 }}>
                            MBTI를 입력하세요 (테스트 결과에는 영향을 미치지 않습니다.)
                        </Typography>

                        <TextField
                            label="MBTI Type"
                            variant="outlined"
                            value={tempName}
                            onChange={(e) => setTempName(e.target.value)}
                            sx={{ mb: 2 }}
                        />
                        <Button variant="contained" color="primary" onClick={handleNameSubmit}>
                            시작하기
                        </Button>
                    </Box>
                )}
            </Box>
        </Container>
    );
}

export default AppContent;
