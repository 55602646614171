import React, { useEffect } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';
import ReactGA from 'react-ga4';

function ProgressIndicator() {
    useEffect(() => {
        ReactGA.event({
            category: 'Ad',
            action: 'Viewed',
            label: 'Ad Page Viewed'
        });

        const iframe = document.getElementById('ad-iframe');
        if (iframe) {
            iframe.onload = () => {
                ReactGA.event({
                    category: 'Ad',
                    action: 'Loaded',
                    label: 'Ad Iframe Loaded'
                });
            };
        }

        return () => {
            ReactGA.event({
                category: 'Ad',
                action: 'Exited',
                label: 'Ad Page Exited'
            });
        };
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress />
            <Typography variant="h4" sx={{ marginTop: 2 }}>
                결과를 준비 중입니다...
            </Typography>
            <iframe
                id="ad-iframe"
                src="https://ellisonleao.github.io/clumsy-bird"
                style={{
                    marginTop: '20px',
                    width: '100%',
                    height: '50vw',
                    maxWidth: '900px',
                    maxHeight: '600px',
                    aspectRatio: '3 / 2'
                }}
            ></iframe>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
                여기에 광고가 표시됩니다.
            </Typography>
        </Box>
    );
}

export default ProgressIndicator;
