import React from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContent from './AppContent';

ReactGA.initialize('G-7PDGQBLPZ1');


function App() {
    return (
        <Router>
            <AppContent />
        </Router>
    );
}

export default App;
