// Results.js

import React, { useEffect, useState } from 'react';
import { Paper, Typography, Grid, Divider, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Bar, Radar, Doughnut, PolarArea, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    RadarController,
    DoughnutController,
    PolarAreaController,
    LineController,
    PointElement,
    LineElement,
    ArcElement,
    RadialLinearScale,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import axios from "axios";
import ReactGA from 'react-ga4';

const ENV = process.env.ENVIRONMENT;
// const API_URL = ENV === 'production' ? "https://api.mentai.jypark.dev" : "http://localhost:5001";
const API_URL = "https://api.mentai.jypark.dev"
ChartJS.register(
    CategoryScale, LinearScale, BarElement, RadarController, DoughnutController, PolarAreaController, LineController, PointElement, LineElement, ArcElement, RadialLinearScale, Title, Tooltip, Legend
);

const StyledPaper = styled(Paper)({
    padding: '20px',
    margin: '10px',
    maxWidth: '1200px',
    width: '100%',
    backgroundColor: '#f5f5f5', // Example background color
});

const TitleTypography = styled(Typography)({
    fontSize: '32px',
    fontWeight: 'bold',
    color: '#333', // Example title color
});

const SubtitleTypography = styled(Typography)({
    fontSize: '22px',
    fontWeight: '500',
    color: '#555', // Example subtitle color
});

const horizontalBarOptions = {
    indexAxis: 'y',
    scales: {
        x: {
            beginAtZero: true,
            max: 100,
        }
    }
};

const standardBarOptions = {
    scales: {
        y: {
            beginAtZero: true,
            max: 100,
        }
    }
};

function Results({ response }) {
    const [detailResults, setDetailResults] = useState(null);
    const [detailLoading, setDetailLoading] = useState(false);

    const loadDetailResults = (scores) => {
        setDetailLoading(true);
        axios.post(`${API_URL}/details`, { scores })
            .then(response => {
                setDetailResults(response.data);
                setDetailLoading(false);
                ReactGA.event({
                    category: 'Results',
                    action: 'Detail Results Loaded',
                    label: 'Detail Results Loaded Successfully'
                });
            })
            .catch(error => {
                console.error('Error submitting answers: ', error);
                setDetailLoading(false); // 에러 발생 시 로딩 종료
                ReactGA.event({
                    category: 'Error',
                    action: 'Detail Results Load Error',
                    label: error.message
                });
            });
    };

    const createMBTIData = (mbtiScores) => {
        const mbtiDimensions = [['E', 'I'], ['N', 'S'], ['F', 'T'], ['J', 'P']];
        const labels = mbtiDimensions.map(dim => `${dim[0]}/${dim[1]}`);
        const data = mbtiDimensions.map(([first, second]) => {
            const firstScore = mbtiScores[first] || 0;
            const secondScore = mbtiScores[second] || 0;
            const total = firstScore + secondScore;
            return total > 0 ? (firstScore / total) * 100 : 50;
        });

        return {
            labels,
            datasets: [
                {
                    label: 'MBTI Percentage',
                    data,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 206, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)'
                    ],
                    borderWidth: 1
                }
            ],
        };
    };

    const createBarChartData = (label, scores) => {
        const labels = Object.keys(scores);
        const data = labels.map((label) => scores[label]);

        return {
            labels,
            datasets: [
                {
                    label: label,
                    data,
                    backgroundColor: 'rgba(75, 192, 192, 0.5)',
                },
            ],
        };
    };

    // 각 테스트 결과에 따른 적절한 차트 선택 및 렌더링
    const renderChart = (testName, result, score, chartType) => {
        if (!score) return <Typography variant="body1">{testName} results not available.</Typography>;

        let chartComponent;

        switch (chartType) {
            case 'horizontalBar':
                chartComponent = <Bar data={createMBTIData(score)} options={horizontalBarOptions} />;
                break;
            case 'bar':
                chartComponent = <Bar data={createBarChartData(testName, score)} options={standardBarOptions} />;
                break;
            case 'radar':
                chartComponent = <Radar data={createBarChartData(testName, score)} />;
                break;
            case 'doughnut':
                chartComponent = <Doughnut data={createBarChartData(testName, score)} />;
                break;
            case 'polarArea':
                chartComponent = <PolarArea data={createBarChartData(testName, score)} />;
                break;
            case 'line':
                chartComponent = <Line data={createBarChartData(testName, score)} />;
                break;
            default:
                chartComponent = <Typography variant="body1">{testName} results: {JSON.stringify(score)}</Typography>;
                break;
        }

        return (
            <div>
                <Typography variant="body1">{testName}</Typography>
                {chartComponent}
                <br />
                <Typography variant="h5" component="h5">
                    Type
                </Typography>
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                    {result.type}
                </Typography>
                <br />
                <Typography variant="h5" component="h5">
                    Advice
                </Typography>
                <Typography variant="body1" style={{ marginTop: '10px' }}>
                    {result.advice}
                </Typography>
                <br />
            </div>
        );
    };

    useEffect(() => {
        ReactGA.event({
            category: 'Results',
            action: 'Viewed',
            label: 'Results Page Viewed'
        });
        loadDetailResults(response.scores);
    }, [response.scores]);

    useEffect(() => {
        if (detailResults) {
            Object.keys(detailResults.scores).forEach(testName => {
                ReactGA.event({
                    category: 'Chart',
                    action: 'Rendered',
                    label: `${testName} Chart Rendered`
                });
            });
        }
    }, [detailResults]);

    return (
        <StyledPaper>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TitleTypography gutterBottom>
                        Your Test Results
                    </TitleTypography>
                    <Divider>
                        <Typography variant="h5" component="h5">
                            Overall
                        </Typography>
                    </Divider>
                    <br />
                    <SubtitleTypography variant="h5" component="h5" gutterBottom>
                        Strength
                    </SubtitleTypography>
                    <Typography variant="body1">
                        {response.answers.strength} {/* General description */}
                    </Typography>
                    <br />
                    <SubtitleTypography variant="h5" component="h5" gutterBottom>
                        Weakness
                    </SubtitleTypography>
                    <Typography variant="body1">
                        {response.answers.weakness} {/* General description */}
                    </Typography>
                    <br />
                    <SubtitleTypography variant="h5" component="h5" gutterBottom>
                        Overall
                    </SubtitleTypography>
                    <Typography variant="body1">
                        {response.answers.overall} {/* General description */}
                    </Typography>
                    <br />
                    <SubtitleTypography variant="h5" component="h5" gutterBottom>
                        Goal
                    </SubtitleTypography>
                    <Typography variant="body1">
                        {response.answers.goal} {/* General description */}
                    </Typography>
                    <br />
                    <Divider>
                        <Typography variant="h5" component="h5">
                            Details
                        </Typography>
                    </Divider>
                    <br />
                </Grid>
                {!detailResults ? (
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <CircularProgress />
                        <Typography variant="h6" sx={{ marginTop: 2 }}>
                            세부 테스트 결과를 계산하는 중입니다..
                        </Typography>
                        <Typography variant="h6" sx={{ marginTop: 2 }}>
                            여기에도 광고가 표시됩니다 ㅋㅋ
                        </Typography>
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={12} md={6}>
                            {renderChart("MBTI", detailResults.answers.mbti, detailResults.scores.mbti, "horizontalBar")}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {renderChart("Big Five", detailResults.answers.big_five, detailResults.scores.big_five, "bar")}
                        </Grid>
                        <br />
                        <Divider />
                        <Grid item xs={12} md={6}>
                            {renderChart("DISC", detailResults.answers.disc, detailResults.scores.disc, "radar")}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {renderChart("Strengths Finder", detailResults.answers.strengths_finder, detailResults.scores.strengths_finder, "polarArea")}
                        </Grid>
                        <Divider />
                        <Grid item xs={12} md={6}>
                            {renderChart("16 PF", detailResults.answers["16pf"], detailResults.scores["16pf"], "polarArea")}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {renderChart("Enneagram", detailResults.answers.enneagram, detailResults.scores.enneagram, "polarArea")}
                        </Grid>
                    </>
                )}
            </Grid>
        </StyledPaper>
    );
}

export default Results;
